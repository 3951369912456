import { HydrateOn, Icon, Popover } from '@loveholidays/design-system';
import React, { FC } from 'react';

import { SearchSortingExplainerContent } from './SearchSortingExplainerContent';

const commonStyles = {
  fontSize: 0,
  lineHeight: 0,
};

type SearchSortingExplainerProps = {
  className?: string;
};

export const SearchSortingExplainerTooltip: FC<SearchSortingExplainerProps> = ({ className }) => {
  return (
    <HydrateOn
      eventNames={['mouseenter']}
      className={className}
      sx={commonStyles}
    >
      <Popover
        content={
          <SearchSortingExplainerContent
            sx={{ marginLeft: '4xs', padding: 'xs' }}
            showTitle
          />
        }
        placement="bottom-end"
        trigger="hover"
        sx={commonStyles}
      >
        <Icon
          size="16"
          name="Markers/Tooltip"
          color="iconDimmedmedium"
        />
      </Popover>
    </HydrateOn>
  );
};
