import { Icon, Link, PageContainer, Title } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { Fragment } from 'react';

import { SearchFilters } from './filters/SearchFilters';
import { MapSearchButton } from './MapSearchButton/MapSearchButton';
import { OtherSuggestedHotelsLabel } from './OtherSuggestedHotelsLabel';
import { SearchResults } from './SearchResults';
import { SortOptionsSelector } from './SortOptionsSelector';
import { TotalResultsCounter } from './TotalResultsCounter';
import { LoadableFeedbackComponent } from '@Components/Checkout/Widgets/LoadableFeedbackComponent';
import { LoadableLoginToUseFavouritesModal } from '@Components/Favourites/modals/LoadableLoginToUseFavouritesModal';
import { LoadableNotification } from '@Components/Favourites/notifications/LoadableNotification';
import DynamicPackageSearchSummaryForm from '@Components/SearchForm/DynamicPackageSearchSummaryForm';
import { SearchConfigurationContext } from '@Components/SearchForm/SearchConfigurationContext';
import { StickyFilterAndSort } from '@Components/SearchForm/StickyFilterAndSort';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';
import { WithClickTracking } from '@Core/tracking/WithClickTracking';
import { usePathTemplate } from '@Core/usePathTemplate';
import { HotelDeeplinkModal } from '@Pages/search-results/hotel-details-modal/HotelDeeplinkModal';
import { PinnedSearchResults } from '@Pages/search-results/PinnedSearchResults';
import { SearchResultsStoreUpdater } from '@Pages/search-results/store-updaters/SearchResultsStoreUpdater';
import { SearchSelectionStoreInitialValue } from '@Stores/SearchSelectionStore';
import { StoreOverride } from '@Stores/StoreContext';

type SearchResultsProps = {
  title?: string;
  searchSelection: SearchSelectionStoreInitialValue;
};

export const SearchResultsComponent: React.FC<SearchResultsProps> = ({
  searchSelection,
  title,
}) => {
  const { t } = useTranslation();
  const showFeedbackWidget = useFeatureFlag('ShowFeedbackWidget');
  const mapSearchEnabled = useFeatureFlag('MapSearch');
  const srpPath = usePathTemplate('srp');

  useFeatureFlag('SearchResultsPageAATest');
  useFeatureFlag('CustomerIdAA');

  return (
    <Fragment>
      <LoadableNotification />
      <StoreOverride
        initialValues={{
          searchSelection,
        }}
      >
        <SearchResultsStoreUpdater pageType="srp">
          <SearchConfigurationContext.Provider
            value={{
              showDestinationInput: true,
              useClientSideNavigation: true,
              showSearchButton: false,
              showFullFormMobile: false,
              isFlexibilityEnabled: true,
            }}
          >
            <section
              data-id="search-form-wrapper"
              sx={{
                backgroundColor: 'podEmphasisedBackground',
                paddingTop: ['xs', null, 'xl'],
                paddingBottom: [null, null, 'xl'],
              }}
            >
              <PageContainer>
                <DynamicPackageSearchSummaryForm />
              </PageContainer>
            </section>
            <StickyFilterAndSort />
            <section
              sx={{
                backgroundColor: 'backgroundLightsubtle',
              }}
            >
              <PageContainer
                sx={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                  paddingY: ['xs', null, 'xl'],
                }}
              >
                {!!title && (
                  <Fragment>
                    <WithClickTracking action="deals-view-all-holidays">
                      <Link
                        data-id="deals-view-allholidays"
                        href={srpPath}
                        internal
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          textDecoration: 'none',
                        }}
                      >
                        <Icon
                          name="Actions/MovePrevious"
                          size="16"
                        />
                        {t('searchResults.viewAll')}
                      </Link>
                    </WithClickTracking>
                    <Title
                      as="h1"
                      variant="medium"
                    >
                      {title}
                    </Title>
                  </Fragment>
                )}
                <div
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexBasis: '100%',
                    flex: '0 0 auto',
                    marginBottom: ['xs', null, 'xl'],
                    ...(mapSearchEnabled ? { minHeight: '36px' } : {}),
                  }}
                >
                  {title ? (
                    <TotalResultsCounter
                      as="h3"
                      variant="xxsmall"
                      sx={{
                        fontSize: ['l', null, null],
                        marginBottom: 0,
                      }}
                    />
                  ) : (
                    <TotalResultsCounter />
                  )}
                  <SortOptionsSelector
                    sx={{
                      display: ['none', 'none', 'flex'],
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      marginLeft: 'auto',
                    }}
                  />
                  {mapSearchEnabled && (
                    <MapSearchButton
                      sx={{
                        marginLeft: ['auto', 'auto', 'xs'],
                        display: ['none', 'none', 'flex'],
                      }}
                    />
                  )}
                </div>

                <div
                  sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    justifyContent: 'space-between',
                    flexDirection: ['column', 'row'],
                  }}
                >
                  <SearchFilters
                    sx={{
                      flexBasis: [null, null, 'calc(25% - 6px)'],
                    }}
                  />

                  <section
                    sx={{
                      flexBasis: ['100%', '100%', ({ space }) => `calc(75% - ${space.xs}px)`],
                    }}
                  >
                    <PinnedSearchResults
                      sx={{
                        marginBottom: 'xl',
                      }}
                    />

                    <OtherSuggestedHotelsLabel
                      sx={{
                        marginBottom: 'xl',
                      }}
                    />

                    <SearchResults />
                  </section>
                </div>
                <HotelDeeplinkModal />
              </PageContainer>
            </section>
          </SearchConfigurationContext.Provider>
        </SearchResultsStoreUpdater>
      </StoreOverride>
      {showFeedbackWidget && <LoadableFeedbackComponent />}
      <LoadableLoginToUseFavouritesModal />
    </Fragment>
  );
};
