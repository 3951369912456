import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { ClassNameProps } from '@ComponentProps';
import { Button } from '@Components/Button/Button';
import { useSearchResultsStore, useSearchSelectionStore } from '@Stores/StoreContext';

interface LoadMoreResultsButtonProps extends ClassNameProps {
  loading?: boolean;
}

export const LoadMoreResultsButton: React.FC<LoadMoreResultsButtonProps> = ({
  className,
  loading = false,
}) => {
  const { t } = useTranslation();
  const [nextPageOffset] = useSearchResultsStore((state) => [state.nextPageOffset]);
  const setResultsStartIndex = useSearchSelectionStore((state) => state.setResultsStartIndex);

  if (!nextPageOffset) {
    return null;
  }

  return (
    <Button
      data-id="load-more-button"
      variant="Outline"
      loading={loading}
      eventLabel={`${nextPageOffset}`}
      trackingAction="pagination"
      className={className}
      size="64"
      stretch
      onClick={() => setResultsStartIndex(nextPageOffset)}
    >
      {t('loadMore')}
    </Button>
  );
};
