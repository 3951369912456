import { Skeleton } from '@loveholidays/design-system';
import React, { Fragment, useMemo } from 'react';

import { PopularFilters } from './PopularFilters';
import { SearchFilter } from './SearchFilter';
import { SortOptionFilter } from './SortOptionFilter';
import { PriceQuoteSwitch } from '@Components/PriceQuoteSwitch/PriceQuoteSwitch';
import { useSearchResultsStore } from '@Stores/StoreContext';

interface SearchFiltersContentProps {
  showSortOptions?: boolean;
  isInModal?: boolean;
}

/**
 * Renders the content of the search filter sidebar and the modal.
 */
export const SearchFiltersContent: React.FC<SearchFiltersContentProps> = ({
  showSortOptions = false,
  isInModal = false,
  children,
}) => {
  const [orderedFilters, fetching] = useSearchResultsStore((state) => [
    state.orderedFilters,
    state.fetching,
  ]);

  const isLoading = !orderedFilters.length && fetching;

  if (isLoading) {
    return (
      <Fragment>
        {Array(3)
          .fill(0)
          .map((_, i) => (
            <Skeleton
              key={i}
              sx={{
                height: i === 0 ? 64 : 270,
                marginBottom: '2xs',
                borderRadius: 16,
                backgroundColor: 'backgroundLightneutral',
              }}
            />
          ))}
      </Fragment>
    );
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(
    () => (
      <Fragment>
        <div
          sx={{
            backgroundColor: 'backgroundWhite',
            borderWidth: 'outlinedStrokeWeight',
            borderStyle: 'solid',
            borderColor: 'strokeLightneutral',
            borderRadius: 16,
            padding: 's',
            marginBottom: '2xs',
          }}
        >
          <PriceQuoteSwitch />
        </div>

        {children}

        {showSortOptions && (
          <SortOptionFilter
            sx={{
              marginBottom: '2xs',
            }}
          />
        )}

        <PopularFilters
          sx={{
            marginBottom: '2xs',
          }}
        />

        {orderedFilters.map((filter) => (
          <SearchFilter
            key={`search-filter-${filter.id}`}
            isInModal={isInModal}
            {...filter}
          />
        ))}
      </Fragment>
    ),
    [orderedFilters, showSortOptions, children, isInModal],
  );
};
