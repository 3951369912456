import { Clickable, Icon, Label } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import SearchSelectionModal from './SearchSelectionModal';
import { getNumberOfSelectedFilters } from '@Components/LayoutComponents/SearchResultsComponent/filters/getNumberOfSelectedFilters';
import { SearchFiltersContent } from '@Components/LayoutComponents/SearchResultsComponent/filters/SearchFiltersContent';
import { useSearchSelectionStore } from '@Stores/StoreContext';

type FilterButtonProps = {
  onClose?: () => void;
};

const FilterButton: React.FC<FilterButtonProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const numberOfSelectedFilters = useSearchSelectionStore((state) =>
    getNumberOfSelectedFilters(state.filters),
  );

  return (
    <SearchSelectionModal
      label="filter"
      eventAction="filter"
      onClose={onClose}
      childButton={
        <Clickable
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            borderRight: 'solid',
            borderWidth: '1px',
            borderColor: 'strokeDarkneutral',
          }}
        >
          <Icon
            name="Navigation/Preferences"
            size="16"
            sx={{
              marginLeft: '2xs',
              marginRight: '3xs',
            }}
          />
          <Label
            variant="mediumbold"
            sx={{ color: 'textPrimarydark' }}
          >
            {t('filters.filter')}
          </Label>
          {!!numberOfSelectedFilters && (
            <Label
              variant="extrasmallbold"
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: 'rounded',
                height: '20',
                width: numberOfSelectedFilters > 9 ? '24' : '20',
                textAlign: 'center',
                backgroundColor: 'textPrimarydark',
                color: 'textLight',
                justifyContent: 'center',
                marginLeft: '3xs',
              }}
            >
              {numberOfSelectedFilters > 9 ? ' 9+' : numberOfSelectedFilters}
            </Label>
          )}
        </Clickable>
      }
      childContent={<SearchFiltersContent isInModal />}
    />
  );
};

export default FilterButton;
