import { useLayoutEffect } from '@loveholidays/design-system';
import React, { useRef, useState } from 'react';
import { SxStyleProp } from 'theme-ui';

import { ClassNameProps } from '@ComponentProps';
import { useShowAsSticky } from '@Components/Sticky/useShowAsSticky';

type StickyElementProps = ClassNameProps & {
  stickySx?: SxStyleProp;
};

export const Sticky: React.FC<StickyElementProps> = ({
  className,
  'data-id': dataId,
  stickySx,
  children,
}) => {
  // Handle sticky height to mitigate CLS
  const [height, setHeight] = useState<number>(0);
  const stickyEl = useRef<HTMLDivElement>(null);

  const { isSticky, anchorRef } = useShowAsSticky();

  useLayoutEffect(() => {
    if (stickyEl.current?.clientHeight) {
      setHeight(stickyEl.current.clientHeight);
    }
  }, [setHeight]);

  return (
    <div
      className={className}
      sx={{
        backgroundColor: 'baseLight',
        minHeight: height,
      }}
    >
      {/* This empty div is required to allow for the
      smooth transition to sticky as the customer
      scrolls down the page */}
      <div ref={anchorRef} />
      <div
        ref={stickyEl}
        data-id={dataId}
        sx={{
          width: '100%',
          ...(isSticky && {
            ...stickySx,
            transition: '0.5s',
            position: 'fixed',
            top: 0,
            right: 0,
            left: 0,
            zIndex: 'stickyContent',
          }),
        }}
      >
        {children}
      </div>
    </div>
  );
};
