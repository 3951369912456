import loadable from '@loadable/component';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { useCallback, useMemo } from 'react';

import { SortOption } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';
import { trackEventClick } from '@Core/tracking/hooks/useInteractionTracking';
import { Filter, FilterItemModel } from '@DesignSystemComponents/Filter';
import { useSearchAvailabilityStore, useSearchSelectionStore } from '@Stores/StoreContext';

const LoadableSearchSortingExplainerModal = loadable(
  () => import('../SearchSortingExplainer/SearchSortingExplainerModal'),
  {
    resolveComponent: (module) => module.SearchSortingExplainerModal,
  },
);

/**
 * This component renders the sort option selector as a radio-style filter.
 */
export const SortOptionFilter: React.FC<ClassNameProps> = ({ className }) => {
  const { t } = useTranslation();
  const sortOptions = useSearchAvailabilityStore((state) => state.sort);
  const [sort, setSort] = useSearchSelectionStore((state) => [state.sort, state.setSort]);
  const searchSortingExplainer = useFeatureFlag('SearchSortingExplainer');

  const onChange = useCallback(
    (value: SortOption) => {
      setSort(value);
      trackEventClick('SRPSortSelector', value);
    },
    [setSort],
  );

  const items: FilterItemModel[] = useMemo(
    () =>
      sortOptions.map((value) => ({
        id: value,
        label: t('sort.sort', { context: value }),
      })),
    [sortOptions, t],
  );

  return (
    <Filter
      id="search-sort"
      type="RADIO"
      title={
        <div
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {t('sort.sortBy')}
          {searchSortingExplainer && (
            <LoadableSearchSortingExplainerModal sx={{ marginLeft: '4xs' }} />
          )}
        </div>
      }
      titleAs="h3"
      items={items}
      selectedItemIds={[sort]}
      onChange={(selected) => {
        onChange(selected as SortOption);
      }}
      collapsedByDefault
      className={className}
      sx={{
        backgroundColor: 'backgroundWhite',
        borderWidth: 'outlinedStrokeWeight',
        borderStyle: 'solid',
        borderColor: 'strokeLightneutral',
        borderRadius: 16,
      }}
    />
  );
};
