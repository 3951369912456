import { ModalHeader, TextLink } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { useEffect, Fragment, ReactElement, ReactNode, useCallback, useRef } from 'react';

import { Button } from '@Components/Button/Button';
import { getCurrentSelectionValue } from '@Components/LayoutComponents/SearchResultsComponent/filters/SearchFilterModalOpener';
import { LoadableModal } from '@Components/Modal/LoadableModal';
import { useModal } from '@Components/Modal/useModal';
import { windowScrollTo } from '@Core/compatibility/scroll';
import { getScrollBehavior } from '@Core/scroll-helpers/getScrollBehavior';
import { trackEventClick } from '@Core/tracking/hooks/useInteractionTracking';
import {
  useSearchResultsStore,
  useSearchSelectionStore,
  useStoreContext,
} from '@Stores/StoreContext';

type SearchSelectionModalProps = {
  label: string;
  eventAction: string;
  childButton: ReactNode;
  childContent: ReactElement;
  excludeResetButton?: boolean;
  onClose?: () => void;
};

export const SearchSelectionModal: React.FC<SearchSelectionModalProps> = ({
  label,
  eventAction,
  childButton,
  childContent,
  excludeResetButton,
  onClose,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen, setModalClose] = useModal();
  const initialSelectionValue = useRef<string>('');
  const { searchSelection } = useStoreContext();
  const resetFilters = useSearchSelectionStore((state) => state.resetFilters);
  const totalResults = useSearchResultsStore((state) => state.totalResults);

  const openModal = useCallback(() => {
    trackEventClick(eventAction);
    initialSelectionValue.current = getCurrentSelectionValue(searchSelection);

    setModalOpen();
  }, [setModalOpen, eventAction, searchSelection]);

  const closeModal = useCallback(() => {
    setModalClose();
    onClose?.();
  }, [onClose, setModalClose]);

  useEffect(() => {
    // we grab the height of the page header and the search form to scroll the page to the point
    // where the filter & sort tab is at the top, and 'x holidays found' is in view
    if (
      !isModalOpen &&
      initialSelectionValue.current !== getCurrentSelectionValue(searchSelection)
    ) {
      const searchForm = document.querySelector('[data-id="search-form-wrapper"]');
      const header = document.querySelector('[data-id="header"]');
      let yPos = 0;
      if (searchForm) {
        yPos += searchForm.getBoundingClientRect().height;
      }
      if (header) {
        yPos += header.getBoundingClientRect().height;
      }

      if (yPos < window.scrollY) {
        windowScrollTo({ top: yPos, behavior: getScrollBehavior() });
      }
    }
  }, [isModalOpen, searchSelection]);

  return (
    <Fragment>
      <div
        data-id={`${label}-search-selection`}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
        onClick={openModal}
      >
        {childButton}
      </div>
      {isModalOpen && (
        <LoadableModal
          show={isModalOpen}
          onClose={closeModal}
          data-id="modal-container"
          Header={
            <ModalHeader
              onClose={closeModal}
              layout="small"
              label={t(`filters.${label}`)}
            />
          }
          Content={childContent}
          fullWidthActions
          Actions={
            <div
              sx={{
                display: 'flex',
                justifyContent: excludeResetButton ? 'end' : 'space-between',
                gap: 'xs',
                alignItems: 'center',
              }}
            >
              {!excludeResetButton && (
                <TextLink
                  text={t('filters.resetFilters')}
                  size={20}
                  variant="Secondary"
                  trackingAction="resetFilters"
                  onClick={resetFilters}
                  sx={{
                    paddingLeft: [0, 'xs'],
                  }}
                />
              )}
              <Button
                variant="Primary"
                size="48"
                data-id="apply-btn"
                onClick={closeModal}
                trackingAction="applyFilters"
                sx={{
                  flexGrow: [1, 0, null],
                  minWidth: [null, '245px', null],
                }}
              >
                {t('showXHolidays', { count: totalResults })}
              </Button>
            </div>
          }
        />
      )}
    </Fragment>
  );
};

export default SearchSelectionModal;
