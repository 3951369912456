import { Label, Paragraph, TextLink } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { FC } from 'react';

import { useAppContext } from '@Contexts/contexts';

type SearchSortingExplainerContentProps = {
  showTitle?: boolean;
  className?: string;
};

export const SearchSortingExplainerContent: FC<SearchSortingExplainerContentProps> = ({
  showTitle,
  className,
}) => {
  const { t } = useTranslation();
  const { site } = useAppContext();

  return (
    <div className={className}>
      {showTitle && (
        <Label
          as="p"
          variant="mediumbold"
          sx={{ marginBottom: '3xs' }}
        >
          {t('sortExplainer.title')}
        </Label>
      )}
      <Paragraph
        variant="extrasmall"
        sx={{ marginBottom: '3xs' }}
      >
        {t('sortExplainer.content')}
      </Paragraph>
      <TextLink
        size={20}
        text={t('sortExplainer.cta')}
        href={site.coreLinks?.faqHotelRanking?.url || ''}
        target="_blank"
        trackingAction="search-sort-explainer"
        variant="Primary"
      />
    </div>
  );
};
