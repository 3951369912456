import React from 'react';

import { SearchFiltersContent } from './SearchFiltersContent';
import { ClassNameProps } from '@ComponentProps';

/**
 * Renders the filter sidebar on Desktop.
 */
export const SearchFiltersSidebar: React.FC<ClassNameProps> = ({ className }) => {
  return (
    <section className={className}>
      <SearchFiltersContent />
    </section>
  );
};
