import { Selector } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { getNumberOfSelectedFilters } from './getNumberOfSelectedFilters';
import { SearchFiltersContent } from './SearchFiltersContent';
import { ClassNameProps } from '@ComponentProps';
import SearchSelectionModal from '@Components/SearchForm/SearchSelectionModal';
import { Store } from '@Core/createStore';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';
import { SearchSelectionStore } from '@Stores/SearchSelectionStore';
import { useSearchSelectionStore } from '@Stores/StoreContext';

/**
 * Returns a subset of the search selection which is used to compare states when closing the modal.
 */
export const getCurrentSelectionValue = (searchSelectionStore: Store<SearchSelectionStore>) => {
  const { sort, filters } = searchSelectionStore.getState();

  return JSON.stringify({ sort, filters });
};

/**
 * This is used on Mobile and Tablet to render a floating Filter button which opens the filter modal
 */
export const SearchFilterModalOpener: React.FC<ClassNameProps> = ({ className }) => {
  const { t } = useTranslation();

  const numberOfSelectedFilters = useSearchSelectionStore((state) =>
    getNumberOfSelectedFilters(state.filters),
  );

  // TODO: check tests for issues around onclick as we have classname set
  const mapSearchEnabled = useFeatureFlag('MapSearch');

  return (
    <SearchSelectionModal
      label="sortAndFilter"
      eventAction="filter"
      childButton={
        <div className={className}>
          <Selector
            data-id="filter-fab"
            count={numberOfSelectedFilters || undefined}
            isSelected
            icon="Actions/Select"
            variant="Heavy"
            size="48"
            label={t('filters.sortAndFilter')}
            sx={{
              position: 'fixed',
              bottom: mapSearchEnabled ? 'xs' : '2xl',
              left: '50%',
              transform: 'translateX(-50%)',
              width: 'max-content',
              boxShadow: 'elevationFloating',
              zIndex: 'filterOpener',
            }}
          />
        </div>
      }
      childContent={
        <SearchFiltersContent
          showSortOptions
          isInModal
        />
      }
    />
  );
};
