import React from 'react';

import { MapSearchButton } from '@Components/LayoutComponents/SearchResultsComponent/MapSearchButton/MapSearchButton';
import { LazyHydrateWrapper } from '@Components/LazyHydrateWrapper/LazyHydrateWrapper';
import FilterButton from '@Components/SearchForm/FilterButton';
import { SortButton } from '@Components/SearchForm/SortButton';
import { Sticky } from '@Components/Sticky/Sticky';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';
import { useBreakpoint } from '@Core/useBreakpoint';

const isServer = typeof window === 'undefined';

export const StickyFilterAndSort: React.FC = () => {
  const mapSearch = useFeatureFlag('MapSearch');
  const { isDesktop } = useBreakpoint();

  return (
    <LazyHydrateWrapper>
      {(isServer || !isDesktop()) && (
        <Sticky
          data-id="sticky-filter-sort-map"
          sx={{
            display: ['flex', null, 'none'],
          }}
          stickySx={{
            boxShadow: 'rgba(0, 0, 0, 0.25) 0px 4px 8px -2px',
            borderColor: 'backgroundWhite',
          }}
        >
          <div
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingY: 'xs',
              backgroundColor: 'baseLight',
            }}
          >
            <FilterButton />
            <SortButton />
            {mapSearch && <MapSearchButton />}
          </div>
        </Sticky>
      )}
    </LazyHydrateWrapper>
  );
};
