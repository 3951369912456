import React, { Fragment, useState } from 'react';

import { DynamicPackageSearchSummaryCondensed } from './DynamicPackageSearchSummaryCondensed';
import { NewDynamicPackageSearchForm } from './NewDynamicPackageSearchForm';
import { trackEventClick } from '@Core/tracking/hooks/useInteractionTracking';

const DynamicPackageSearchSummaryForm: React.FC = () => {
  const [showCondensed, setShowCondensed] = useState(true);

  const switchToForm = () => {
    setShowCondensed(false);
    document.body.scrollIntoView();
    trackEventClick('search-ui-search-form-expanded');
  };

  return (
    <Fragment>
      <NewDynamicPackageSearchForm
        sx={{ display: showCondensed ? ['none', 'block'] : 'block' }}
        isLoose
      />

      {showCondensed && (
        <DynamicPackageSearchSummaryCondensed
          showInputTitleOnMobile
          onEdit={switchToForm}
          sx={{ display: ['block', 'none'] }}
        />
      )}
    </Fragment>
  );
};

export default DynamicPackageSearchSummaryForm;
