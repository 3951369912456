import { useLazyHydration } from '@loveholidays/design-system';
import React, { useEffect } from 'react';

import { ClassNameProps } from '@ComponentProps';

const isServer = typeof window === 'undefined';

/**
 * LazyHydrateWrapper - prevents hydration during first client side render and performs it after hydration finished.
 * This used to render both mobile and desktop components during SSR and prevent hydration both of them on the client
 * until the client side breakpoints available and hydrate only one needed.
 * */
export const LazyHydrateWrapper: React.FC<ClassNameProps> = ({ children, ...props }) => {
  const [setHydrated, LazyHydrateWrapper] = useLazyHydration(
    isServer ? false : !!window.hydrationFinished,
  );

  useEffect(() => {
    if (window.hydrationFinished) {
      setHydrated();
    }
  }, [setHydrated]);

  return <LazyHydrateWrapper {...props}>{children}</LazyHydrateWrapper>;
};
