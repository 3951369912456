import React from 'react';

import { SearchFilterModalOpener } from './SearchFilterModalOpener';
import { SearchFiltersSidebar } from './SearchFiltersSidebar';
import { ClassNameProps } from '@ComponentProps';
import { LazyHydrateWrapper } from '@Components/LazyHydrateWrapper/LazyHydrateWrapper';
import { useBreakpoint } from '@Core/useBreakpoint';
import { useSearchSelectionStore } from '@Stores/StoreContext';

const isServer = typeof window === 'undefined';

/**
 * This is rendered on SRP as a sidebar on desktop, and as a floating button on mobile/tablet.
 *
 * 1. On SSR we render both with responsive CSS
 * 2. During hydration the component is displayed correctly, as both versions are present
 *    in the DOM, and their presence is controlled by responsive CSS.
 * 3. During hydration we skip hydrating them (using LazyHydrateWrapper), as it's pointless to
 *    hydrate the desktop version on mobile, as it will be removed in 4.
 * 4. After the hydration finished, we remove one of the versions which is not needed for the
 *    current breakpoint. We do this to avoid having both subscribing to search store events
 *    and re-rendering the component unnecessarily, as it is not displayed anyway.
 * 5. When the hydration finished we hydrate the version which is needed for the current breakpoint.
 * 6. During client render (e.g. navigating from Homepage to SRP) we render only one of the components.
 */
export const SearchFilters: React.FC<ClassNameProps> = ({ className }) => {
  const isMapOpen = useSearchSelectionStore((state) => state.viewMode) !== 'list';
  const { isDesktop } = useBreakpoint();

  return (
    <LazyHydrateWrapper className={className}>
      {(isServer || isDesktop()) && (
        <SearchFiltersSidebar
          sx={{
            display: ['none', null, 'block'],
          }}
        />
      )}

      {(isServer || !isDesktop()) && isMapOpen && (
        <SearchFilterModalOpener
          sx={{
            display: ['block', null, 'none'],
          }}
        />
      )}
    </LazyHydrateWrapper>
  );
};
