import { Heading } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { ClassNameProps } from '@ComponentProps';
import { useSearchAvailabilityStore, useSearchResultsStore } from '@Stores/StoreContext';

export const OtherSuggestedHotelsLabel: React.FC<ClassNameProps> = ({ className }) => {
  const { t } = useTranslation();
  const destinations = useSearchAvailabilityStore((state) => state.destinations);
  const [fetching, pinnedResultsCount, resultsCount] = useSearchResultsStore((state) => [
    state.fetching,
    state.pinnedResults.length,
    state.results.length,
  ]);

  if (fetching || !pinnedResultsCount || !resultsCount) {
    return null;
  }

  return (
    <Heading
      variant="largebold"
      as="h2"
      className={className}
    >
      {destinations.length
        ? t('otherSuggestedHotelsInX', {
            location: destinations.map((d) => d.name).join(', '),
          })
        : t('otherSuggestedHotels')}
    </Heading>
  );
};
