import { DropdownSelector, Skeleton } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { ChangeEvent, Fragment } from 'react';

import { SearchSortingExplainerTooltip } from './SearchSortingExplainer/SearchSortingExplainerTooltip';
import { SortOption } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';
import { trackEventClick } from '@Core/tracking/hooks/useInteractionTracking';
import {
  useSearchAvailabilityStore,
  useSearchSelectionStore,
  useSearchResultsStore,
} from '@Stores/StoreContext';

export const SortOptionsSelector: React.FC<ClassNameProps> = ({ className }) => {
  const [sort, setSort] = useSearchSelectionStore((state) => [state.sort, state.setSort]);
  const [fetching, results] = useSearchResultsStore((state) => [state.fetching, state.results]);
  const sortOptions = useSearchAvailabilityStore((state) => state.sort);
  const { t } = useTranslation();
  const searchSortingExplainer = useFeatureFlag('SearchSortingExplainer');

  const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value as SortOption;
    setSort(value);
    trackEventClick('SRPSortSelector', value);
  };

  const options = sortOptions.map((value) => ({
    value,
    label: t('sort.sort', { context: value }),
  }));

  const loading = fetching && results.length === 0;

  return (
    <section className={className}>
      {loading && (
        <Skeleton
          sx={{
            height: 35,
            width: 315,
          }}
        />
      )}

      {!loading && (
        <Fragment>
          <div
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: '2xs',
              color: 'textDimmedheavy',
            }}
          >
            <span>{t('sort.sortBy')}</span>
            {searchSortingExplainer && <SearchSortingExplainerTooltip sx={{ marginLeft: '4xs' }} />}
          </div>

          <DropdownSelector
            ariaLabel={t('sortOptionsSelector')}
            data-id="sort-selector"
            variant="Light"
            size="36"
            options={options}
            value={sort}
            onChange={onChange}
            selectStyles={{
              minWidth: '253.5px', // Add to prevent the width from changing in VR tests.
            }}
          />
        </Fragment>
      )}
    </section>
  );
};
