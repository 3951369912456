import { DynamicPackageFiltersInput } from '@AuroraTypes';

export const getNumberOfSelectedFilters = (filters: DynamicPackageFiltersInput) =>
  Object.values(filters).reduce((acc: number, curr) => {
    if (Array.isArray(curr)) {
      return acc + curr.length;
    }
    if (curr) {
      return acc + 1;
    }

    return acc;
  }, 0);
