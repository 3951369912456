import { ComponentProps, Heading, Icon, PillSelector } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { DynamicPackageFiltersInput } from '@AuroraTypes';
import { sendEvent } from '@Core/tracking/sendEvent';
import { TrackingEvent } from '@Core/tracking/types';
import { useSearchResultsStore, useSearchSelectionStore } from '@Stores/StoreContext';
import { Accordion } from '@UX/components/Accordion/Accordion';

export const PopularFilters: React.FC<ComponentProps> = ({ className }) => {
  const { t } = useTranslation();
  const popularFilters = useSearchResultsStore((state) => state.popularFilters);
  const [filters, setFilterSelection] = useSearchSelectionStore((state) => [
    state.filters,
    state.setFilterSelection,
  ]);

  return (
    <Accordion
      data-id="popular-filters-accordion"
      collapsedByDefault={false}
      className={className}
      sx={{
        marginBottom: '2xs',
        backgroundColor: 'backgroundWhite',
        borderWidth: 'outlinedStrokeWeight',
        borderStyle: 'solid',
        borderColor: 'strokeLightneutral',
        borderRadius: 16,
      }}
      accordionHeaderStyle={{
        paddingY: ['3xs', null, 's'],
        paddingX: 's',
      }}
      accordionHeader={
        <Heading
          variant="smallbold"
          as="h3"
        >
          {t('filters.popularFilters')}
          <Icon
            name="Content/Insights"
            size="20"
            sx={{
              marginLeft: '3xs',
              verticalAlign: 'text-top',
            }}
          />
        </Heading>
      }
    >
      <div
        sx={{
          paddingX: 's',
          paddingBottom: '3xs',
        }}
      >
        {popularFilters.map(({ filter, option }) => {
          const filterSelection = filters[filter as keyof DynamicPackageFiltersInput];
          const isSelected = Array.isArray(filterSelection)
            ? (filterSelection as (typeof option.value)[]).includes(option.value)
            : filterSelection === option.value;

          return (
            <PillSelector
              data-id={`popular-filter-${option.label}`}
              key={option.label!}
              label={option.label!}
              selected={isSelected}
              sx={{
                marginRight: '3xs',
                marginBottom: '3xs',
              }}
              onTrigger={() => {
                if (isSelected) {
                  setFilterSelection({
                    [filter]: Array.isArray(filterSelection)
                      ? filterSelection.filter((o) => o !== option.value)
                      : undefined,
                  });
                } else {
                  setFilterSelection({
                    [filter]: Array.isArray(filterSelection)
                      ? [...filterSelection, option.value]
                      : option.value,
                  });
                }

                sendEvent({
                  event: TrackingEvent.popularFilterUsed,
                  action: isSelected ? 'remove' : 'add',
                  label: option.label!,
                });
              }}
            />
          );
        })}
      </div>
    </Accordion>
  );
};
