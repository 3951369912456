import { useTranslation } from '@loveholidays/phrasebook';
import React, { useCallback, useMemo } from 'react';

import { SortOption } from '@AuroraTypes';
import { trackEventClick } from '@Core/tracking/hooks/useInteractionTracking';
import { FilterItemModel } from '@DesignSystemComponents/Filter';
import { FilterGroup } from '@DesignSystemComponents/Filter/FilterGroup';
import { useSearchAvailabilityStore, useSearchSelectionStore } from '@Stores/StoreContext';

export const SortOptionModal: React.FC = () => {
  const { t } = useTranslation();
  const [sort, setSort] = useSearchSelectionStore((state) => [state.sort, state.setSort]);
  const sortOptions = useSearchAvailabilityStore((state) => state.sort);

  const onChange = useCallback(
    (value: SortOption) => {
      setSort(value);
      trackEventClick('SRPSortSelector', value);
    },
    [setSort],
  );

  const items: FilterItemModel[] = useMemo(
    () =>
      sortOptions.map((value) => ({
        id: value,
        label: t('sort.sort', { context: value }),
      })),
    [sortOptions, t],
  );

  return (
    <FilterGroup
      id="sort-option"
      type="RADIO"
      items={items}
      selectedItemIds={[sort] as (string | number)[]}
      onChange={(selected) => {
        onChange(selected as SortOption);
      }}
      sx={{
        paddingBottom: '3xs',
      }}
    />
  );
};
