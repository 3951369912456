import { useEffect, useState, useCallback, useRef } from 'react';

export const useShowAsSticky = () => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const processing = useRef(false);
  const [isSticky, setIsSticky] = useState(false);

  const handleStickyInView = useCallback(() => {
    if (anchorRef?.current) {
      const { top } = anchorRef.current.getBoundingClientRect();

      setIsSticky((prevIsSticky) => {
        if (prevIsSticky && top >= 0) {
          return false;
        } else if (!prevIsSticky && top < 0) {
          return true;
        }

        return prevIsSticky;
      });
    }
  }, [anchorRef]);

  const handleScroll = useCallback(() => {
    if (!processing.current) {
      processing.current = true;
      window.requestAnimationFrame(() => {
        handleStickyInView();
        processing.current = false;
      });
    }
  }, [processing, handleStickyInView]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return { isSticky, anchorRef };
};
